import React, { useContext, useEffect } from "react";
// import { Form, Field } from 'react-final-form';
import {
  Select,
  TextField,
  Paper,
  Grid,
  MenuItem,
} from '@material-ui/core';
import { useLoading, ThreeDots } from '@agney/react-loading';
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Fab from '@material-ui/core/Fab';
import $ from "jquery";
// components
import Widget from "../../components/Widget/Widget";

import Notification from "../../components/Notification";
import { ToastContainer, toast } from "react-toastify";
import { Close as CloseIcon } from "@material-ui/icons";
// styles
import "react-toastify/dist/ReactToastify.css";
import useToastStyles from "./styles";

import { useSelector, useDispatch } from "react-redux";
import { FirebaseContext } from '../../redux';
import { core } from "../core";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}));
var cnt = 0;
var timeid;
// helper variables to fix the problem with state that the original author
// couldn't figure out.
var CC3, CC4, CC5, CC6, CC7, CC8, CC9, CC10, CC11, CC12, CC13, CC14, CC15, CC16,
  CC17, CC18, CC19, CC20, CC21, CPackage, cashFromCustomer, minusDealerFee ,
  FF14, FF15, FF17, FF18, FF19, FF20, FFSelfGen, manager, dbatteryCount,
  dW2Hours, dW2Cost;

export default function Calculator() {
  const { api } = useContext(FirebaseContext);
  const {
    updateCalculator,
    updateAdders
  } = api;
  const auth = useSelector(state => state.auth);
  const calculator = useSelector(state => state.calculator);
  const adders = useSelector(state => state.adders);
  const formulas = useSelector(state => state.formulas);
  const cash = useSelector(state => state.cash);
  const selfgen = useSelector(state => state.selfgen);
  const battery = useSelector(state => state.battery);
  const fee = useSelector(state => state.fee);
  const modules = useSelector(state => state.modules);
  const dispatch = useDispatch();

  //console.log('calc object in index', calculator.info);

  manager = localStorage.getItem("manager");

  var toastClasses = useToastStyles();
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <ThreeDots width="50" />,
  });
  // display state
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  // System Number
  const [C3, setC3] = React.useState('');
  // Name of Customer
  const [C4, setC4] = React.useState('');
  // Sales Rep (NOT EDITABLE)
  const [C5, setC5] = React.useState('');
  // Sales Rep Status Level (NOT EDITABLE)
  const [C6, setC6] = React.useState('');
  // Type of Lead, selfgen or company-provided
  const [C7, setC7] = React.useState('');
  const [C8, setC8] = React.useState('');
  const [C9, setC9] = React.useState('');
  const [C10, setC10] = React.useState('');
  const [C11, setC11] = React.useState('');
  const [C12, setC12] = React.useState('');
  const [C13, setC13] = React.useState('');
  const [C14, setC14] = React.useState('');
  // Cash Payment TO customer
  const [C15, setC15] = React.useState('');
  const [C16, setC16] = React.useState('');
  const [C17, setC17] = React.useState('');
  const [C18, setC18] = React.useState('');
  const [C19, setC19] = React.useState('');
  const [C20, setC20] = React.useState('');
  const [C21, setC21] = React.useState('');
  const [F14, setF14] = React.useState('');
  const [F15, setF15] = React.useState('');
  const [F17, setF17] = React.useState('');
  const [F18, setF18] = React.useState('');
  // Battery Commission
  const [F19, setF19] = React.useState('');
  const [F20, setF20] = React.useState('');
  const [FSelfGen, setFSelfGen] = React.useState('');
  const [dealerFee, setDealerFee] = React.useState([]);
  const [ccash, setCash] = React.useState([]);
  const [bbattery, setBattery] = React.useState([]);
  const [mmodule, setModule] = React.useState([]);
  const [selfgen_lead, setSelfgenLead] = React.useState([]);
  //TODO: connect ppackage to list of all packages from database.
  const [ppackage, setPackage] = React.useState([]);
  const [cashFromCust, setCashFromCustomer] = React.useState('');
  const [woDealerFee, setMinusDealerFee] = React.useState('');
  const [batteryCount, setBatteryCount] = React.useState(1);
  const [W2Hours, setW2Hours] = React.useState(0);
  const [W2Cost, setW2Cost] = React.useState(0);

  const batteryCountOptions = [1,2,3];

  //#####################################toast########################################################
  const sendNotification = (componentProps, options) => {
    return toast(
      <Notification
        {...componentProps}
        className={toastClasses.notificationComponent}
      />,
      options,
    );
  }
  const handleNotificationCall = () => {
    var componentProps;
    componentProps = {
      type: "feedback",
      message: `Thank you for giving us permission to add you to the Commission Calculator app. Please give us 1-2 days to add you to the correct status.`,
      variant: "contained",
      color: "secondary",
    };

    var toastId = sendNotification(componentProps, {
      type: "error",
      position: toast.POSITION.TOP_CENTER,
      progressClassName: toastClasses.progress,
      className: toastClasses.notification,
    });
  }
  const CloseButton = ({ closeToast, className }) => {
    return <CloseIcon className={className} onClick={closeToast} />;
  }

  //#####################################toast########################################################

  const handleChange = async function (event) {
    console.log(`event target: ${event.target.name}: ${event.target.value}`);
    switch (event.target.name) {
      // Type of Lead Change
      case "C7":
        setC7(event.target.value);
        CC7 = event.target.value;
        break;
      case "C8":
        setC8(event.target.value);
        CC8 = event.target.value;
        break;
      case "C9":
        setC9(event.target.value);
        CC9 = event.target.value;
        break;
      case "C12":
        setC12(event.target.value);
        if (event.target.value.indexOf('$') > -1) {
          CC12 = event.target.value.split('$')[1];
        } else {
          CC12 = event.target.value;
        }
        break;
      case "C14":
        setC14(event.target.value);
        CC14 = event.target.value;
        break;
      case "C10":
        setC10(event.target.value);
        CC10 = event.target.value === "" ? "0" : event.target.value;
        calcAdders(CC10);
        break;
      case "C11":
        setC11(event.target.value);
        CC11 = event.target.value;
        break;
      case "package":
        setPackage(event.target.value);
        CPackage = event.target.value;
        break;
      case "cashFromCust":
        setCashFromCustomer(event.target.value);
        cashFromCustomer = event.target.value;
        break;
      case "batteryCount":
        setBatteryCount(event.target.value);
        dbatteryCount = event.target.value;
        break;
      case "W2Hours":
        setW2Hours(event.target.value);
        dW2Hours = event.target.value;
        break;
    }
    // Because we don't put calc in useEffect(), we have to double the number of
    // state objects.
    calc();
  };
  const getNum = (str) => {
    if (typeof str === "string") {
      var num;
      if (str.indexOf(",") > -1) {
        var str_arr = str.split(",");
        var tempStr = "";
        str_arr.map((item) => {
          tempStr += item;
        })
        num = parseFloat(tempStr)
      } else {
        num = parseFloat(str)
      }
      return num;
    } else {
      return str;
    }
  };
  const calcAdders = (sysSize) => {
    let addersData = adders.info;
    let systemSize = getNum(sysSize);
    var sum = 0;
    for (var i = 0; i < 21; i++) {
      addersData[i].sales_cash_price = parseFloat(addersData[i].sales_cash_price);
      switch (addersData[i].scale) {
        case "per watt add-on":
          if (addersData[i].quantity === "Yes") addersData[i].total = addersData[i].sales_cash_price * systemSize;
          else addersData[i].total = 0;
          break;
        case "Added price":
          if (addersData[i].quantity === "Yes") addersData[i].total = addersData[i].sales_cash_price;
          else addersData[i].total = 0;
          break;
        case "per foot":
          addersData[i].total = addersData[i].sales_cash_price * addersData[i].quantity;
          break;
        default:
          addersData[i].total = addersData[i].sales_cash_price * addersData[i].quantity;
          break;
      }
      sum += addersData[i].total;
    }
    addersData[22].total = sum;
    CC18 = sum;
    setC18(sum);
    let uid = auth.refinfo.uid;
    dispatch(updateAdders(uid, addersData));
  }
  React.useEffect(() => {
    if (auth.info.profile.allow) {
      // style();
      let calcData = calculator.info;

      let feeData = fee.info;
      feeData.sort(function (a, b) { return a.Fee - b.Fee });
      setDealerFee(feeData);

      let cashData = cash.info;
      cashData.sort(function (a, b) { return a.cash - b.cash });
      setCash(cashData);

      let batteryData = battery.info;
      batteryData.sort(function (a, b) { return a.ID - b.ID });
      setBattery(batteryData);

      let modulesData = modules.info;
      modulesData.sort(function (a, b) { return a.ID - b.ID });
      setModule(modulesData);

      let selfgenData = selfgen.info;
      selfgenData.sort(function (a, b) { return a.ID - b.ID });
      setSelfgenLead(selfgenData);

      var formulasData = formulas.info;
      var data = core(formulasData, calcData);

      calcData = data.calculator;

      // Double set all state values because we've used React state wrong.
      // System Number
      setC3(calcData.C3); CC3 = calcData.C3;
      // Name of Customer
      setC4(calcData.C4); CC4 = calcData.C4;
      // Sales Rep (NOT EDITABLE)
      setC5(calcData.C5); CC5 = calcData.C5;
      // Sales Rep Status Level (NOT EDITABLE)
      setC6(calcData.C6); CC6 = calcData.C6;
      // Type of Lead, selfgen or company-provided
      setC7(calcData.C7); CC7 = calcData.C7;
      // Module
      setC8(calcData.C8); CC8 = calcData.C8;
      // Battery Type
      setC9(calcData.C9); CC9 = calcData.C9;
      // System Size in watts
      setC10(calcData.C10); CC10 = calcData.C10;
      // Total financed from Sigthen
      setC11(calcData.C11); CC11 = calcData.C11;
      // Dealer Fee
      setC12("$" + calcData.C12); CC12 = calcData.C12;
      // System Price without Dealer Fee
      setC13(calcData.C13); CC13 = calcData.C13;
      // Deduct Cash Back TO Customer
      setC14(calcData.C14); CC14 = calcData.C14;
      // Cash payment FROM Customer
      setC15(calcData.C15); CC15 = calcData.C15;
      // Total System price without dealer fee, plus Cash Payment
      setC16(calcData.C16); CC16 = calcData.C16;
      // Solar Insure Adder (automatic)
      setC17(calcData.C17); CC17 = calcData.C17;
      // Battery Price (automatic)
      setC18(calcData.C18); CC18 = calcData.C18;
      // Total of all Adders
      setC19(calcData.C19); CC19 = calcData.C19;
      // System Price (solar Portion only)
      setC20(calcData.C20); CC20 = calcData.C20;
      // System Price per Watt (solar portion only)
      setC21(calcData.C21); CC21 = calcData.C21;
      // Battery Commission
      setF20(calcData.F20); FF20 = calcData.F20;
      // Overage Commission
      setF19(calcData.F19); FF19 = calcData.F19;
      // SelfGen Lead Commission
      setF18(calcData.F18); FF18 = calcData.F18;
      // BaseLine Commission
      setF17(calcData.F17); FF17 = calcData.F17;
      // Reps' Share of Overrage/watt
      setF15(calcData.F15); FF15 = calcData.F15;
      // Total Overrage per watt
      setF14(calcData.F14); FF14 = calcData.F14;

      // Battery count (actual number of batteries in the package)
      setBatteryCount(calcData.batteryCount); dbatteryCount = calcData.batteryCount;

      setFSelfGen(calcData.FSelfGen); FFSelfGen = calcData.FSelfGen;
      setPackage(calcData.package); CPackage = calcData.package;
      setCashFromCustomer(calcData.cashFromCust); cashFromCustomer = calcData.cashFromCust;
      setMinusDealerFee(calcData.woDealerFee); minusDealerFee = calcData.woDealerFee; 
      setW2Hours(calcData.W2Hours); dW2Hours = calcData.W2Hours;
      setW2Cost(calcData.W2Cost); dW2Cost = calcData.W2Cost;

      setLoading(false);
      timeid = setTimeout(() => {
        style()
      }, 10);
    } else {
      setLoading(true);
      if (cnt === 0) {
        handleNotificationCall();
        cnt++;
      }
    }
  }, [
    auth.info.profile,
    calculator.info,
    adders.info,
    formulas.info,
    cash.info,
    selfgen.info,
    battery.info,
    fee.info,
    modules.info])
  const style = () => {
    //------------------------------------------------------------jquery style--------------------------------------------------
    // TODO: clean this up. Suggest styled components
    $("#C3").css({ "color": "black" });
    $("#C3").css({ "text-align": "center" });
    $("#C4").css({ "color": "black" });
    $("#C4").css({ "text-align": "center" });
    $("#C10").css({ "color": "black" });
    // $("#C10").css({"width":"10%"});
    $("#C10").css({ "text-align": "center" });
    $("#C11").css({ "color": "black" });
    $("#C11").css({ "text-align": "center" });
    $("#C13").css({ "color": "black" });
    $("#C13").css({ "text-align": "center" });
    $("#C15").css({ "color": "black" });
    $("#C15").css({ "text-align": "center" });
    $("#C16").css({ "color": "black" });
    $("#C16").css({ "text-align": "center" });
    $("#C17").css({ "color": "black" });
    $("#C17").css({ "text-align": "center" });
    $("#C18").css({ "color": "black" });
    $("#C18").css({ "text-align": "center" });
    $("#C19").css({ "color": "black" });
    $("#C19").css({ "text-align": "center" });
    $("#C20").css({ "color": "black" });
    $("#C20").css({ "text-align": "center" });
    $("#C21").css({ "color": "black" });
    $("#C21").css({ "text-align": "center" });
    $("#F14").css({ "color": "black" });
    $("#F14").css({ "text-align": "center" });
    $("#F15").css({ "color": "black" });
    $("#F15").css({ "text-align": "center" });
    $("#F17").css({ "color": "black" });
    $("#F17").css({ "text-align": "center" });
    $("#FSelfGen").css({ "color": "black" });
    $("#FSelfGen").css({ "text-align": "center" });
    $("#F18").css({ "color": "black" });
    $("#F18").css({ "text-align": "center" });
    $("#F19").css({ "color": "black" });
    $("#F19").css({ "text-align": "center" });
    $("#F20").css({ "color": "white" });
    $("#F20").css({ "text-align": "center" });
    $("#W2Cost").css({ "text-align": "center" });
    $("#cashFromCust").css({ "text-align": "center" });
    $("#woDealerFee").css({"text-align":"center", "color": "black"})
    $("#W2Hours").css({ "color": "black", "text-align": "center" });
    $("#W2Cost").css({ "color": "black", "text-align": "center" });
    // console.log("timeid",timeid)
    clearTimeout(timeid)
    //------------------------------------------------------------style end------------------------------------------------------
  }
  const calc = () => {
    // console.log(C3,C4,C5,C6,C7,C8,C9,C10,C11,C12,C13,"C14",C14,C15,C16,C17,C18,C19,C20,C21,F14,F15,F17,F18,F19,F20)
    // console.log("useeffect")

    // System Number
    CC3 = C3;
    // Name of Customer
    CC4 = C4;

    manager = auth.info.profile.other;
    //console.log("testing maanger: ", manager);


    // System size in watts
    CC10 = parseFloat(getNum(CC10));
    // Total financed amount
    CC11 = parseFloat(getNum(CC11));
    // Dealer Fee
    CC12 = parseFloat(CC12);

    // console.log(CC12)
    CC13 = CC11 - CC12;
    setC13(CC13);
    //C15=C13-(C13*C14)
    //  var percent = CC14.split("%")[0]/100;
    //  CC15 = ((CC11-CC12) - ((CC11-CC12)*percent) + parseFloat(getNum(cashFromCust))).toFixed(2);
    //console.log('cc15:', CC15);
    //console.log('percent:', percent);
    //console.log('CC11:', CC11);
    //console.log('CC12:', CC12);
    //console.log('cashfromcust:', cashFromCust);
    setC15(CC15);

    //C16=0.11*C10
    //console.log('CC21 before', CC21);
    //  if(CC21 === "NO FRILLS") {
    //    CC16 = 0;
    //    //console.log('cc21 inside check', CC21)
    //  }else{
    //    CC16 = CC10*0.11;
    //console.log('cc21 didnt pass', CC21)
    //  }
    console.log('c16 at the end', CC16);
    // Total System price without dealer fee, plus cash payment
    setC16(CC16);

    //C17=If($C$9="Enphase 3kw",+#REF!,IF($C$9="Enphase 10kw",+#REF!,IF($C$9="Panasonic 11kw",+#REF!,IF(C9="Panasonic 17kw",+#REF!,0 ))))
    //  if(CC9 === "Enphase 3kw") { CC17 = 11240 }
    //  else{
    //    if(CC9 === "Enphase 10kw") { CC17 = 18680 }
    //    else{
    //      if(CC9 === "Panasonic 11kw") { CC17 = 19880 }
    //      else{
    //        if(CC9 === "Panasonic 17kw") { CC17 = 21130 }
    //        else { CC17 = 0 }
    //      }
    //    }
    //  }
    // Solar Insure Adder
    setC17(CC17);
    //C18
    // console.log("C18",C18,C10)
    // Battery Price
    if (CC18 === "") CC18 = "0";
    // setC18("0");
    //C19=C15-C16-C17-C18
    console.log('CC19', CC19)
    //  CC19 = Math.round((CC15-CC16-CC17-CC18)*100)/100;
    //console.log("CC19: ", CC19);
    //console.log("CC10: ", CC10);
    setC19(CC19);
    //C20=C19/C10
    if (CC10 === 0) {
      CC20 = 0;
    } else {
      CC20 = Math.round(CC19 / parseFloat(getNum(CC10)) * 100) / 100;
    }
    setC20(CC20);

    //  console.log('cc20', CC20);

    //  if (CC20 < 2.6) {
    //    CC21 = "REFUSED";
    //  }else if (CC20 < 2.9) {
    //    CC21 = "NO FRILLS";
    //  }else{CC20 = "ACCEPTED"}
    //  console.log('cc21', CC21);
    //  setC21(CC21);

    setPackage(CPackage);

    // Data send to calc, use the "wrong" state
    var calcObj = {
      C3: CC3 || "",
      C4: CC4 || "",
      C5: CC5 || "",
      C6: CC6 || "",
      C7: CC7 || "",
      C8: CC8 || "",
      C9: CC9 || "",
      C10: CC10 || 0,
      C11: CC11 || 0,
      C12: CC12 || 0,
      C13: CC13 || 0,
      C14: CC14 || 0,
      C15: CC15 || 0,
      C16: CC16 || 0,
      C17: CC17 || 0,
      C18: CC18 || 0,
      C19: CC19 || 0,
      C20: CC20 || 0,
      C21: CC21 || "",
      package: CPackage || "",
      manager: manager || "",
      cashFromCust: cashFromCustomer || 0,
      woDealerFee: minusDealerFee || "0",
      batteryCount: dbatteryCount || 1,
      W2Hours: dW2Hours || 0,
      W2Cost: dW2Cost || 0.0
    }
    var formulasData = formulas.info;
    var data = core(formulasData, calcObj);
    calcObj = data.calculator;
    let uid = auth.refinfo.uid;
    dispatch(updateCalculator(uid, calcObj));
    // console.log("calc Document successfully update!");
  }
  return (
    <>
      <ToastContainer
        className={toastClasses.toastsContainer}
      />
      {loading ?
        <section {...containerProps} style={{ textAlign: "center", marginTop: window.innerHeight / 2 - 100 }}>
          {indicatorEl} {/* renders only while loading */}
        </section> :
        <Grid container spacing={4}>
          {/* <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign:"center"}}>
                <Fab variant="extended" aria-label="add" style={{textTransform: "inherit",marginRight:10, backgroundColor: "rgb(65 129 247)", color: "white"}}>
                  Rep Name   :   {C5}
                </Fab>

                <Fab variant="extended" aria-label="add"  style={{textTransform: "inherit", marginRight:10, backgroundColor: "#0058e9", color: "white"}}>
                  Rep Status   :   {C6}
                </Fab>       

                <Fab variant="extended" color="primary" aria-label="add" style={{textTransform: "inherit", backgroundColor: "blue"}}>
                  Manager: {manager}  
                </Fab>            
             </Grid> */}
          <Grid item lg={7} md={6} sm={12} xs={12}>
            <Widget title="Your Commission Calculator" >
              <Paper style={{ paddingLeft: '4%', paddingRight: '4%' }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: 30 }}>
                    System Number
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    <TextField
                      name="C3"
                      id="C3"
                      label=""
                      value={C3}
                      // onBlur={handleBlur}
                      onChange={(event) => { setC3(event.target.value); }}
                      style={{ textAlign: "center", marginTop: 15, backgroundColor: "#f3efef", width: "75%", color: "black" }} />
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Name of Customer
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    <TextField name="C4" id="C4" label="" value={C4} onChange={(event) => { setC4(event.target.value); }} style={{ textAlign: "center", marginTop: -12, backgroundColor: "#f3efef", width: "75%" }} />
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Select Self Gen Lead
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -15 }}>
                    <Select
                      labelId="C7"
                      name="C7"
                      value={C7}
                      onChange={handleChange}
                      style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%" }}
                    >
                      {selfgen_lead.map(item => {
                        return (<MenuItem key={item.ID} value={item.selfgen}>{item.selfgen}</MenuItem>)
                      })}
                      {/* <MenuItem value={"Self Gen"}>Self</MenuItem>
                    <MenuItem value={"Company"}>Company</MenuItem> */}
                    </Select>
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Select Module
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -15 }}>
                    <Select
                      labelId="C8"
                      name="C8"
                      value={C8}
                      onChange={handleChange}
                      style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%" }}
                    >
                      {mmodule.map(item => {
                        return (<MenuItem key={item.ID} value={item.module}>{item.module}</MenuItem>)
                      })}
                      {/* <MenuItem value={"REC 370"}>REC 370</MenuItem>
                    <MenuItem value={"Mission 345"}>Mission 345</MenuItem> */}
                    </Select>
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Select number of batteries and Battery Type
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center"}}>
                    <Grid container spacing={0} style={{ display: "inline-flex", alignItems: "center", width: "75%", marginTop: "-7.5px" }}>
                      <Grid item lg={8} md={8} sm={8} xs={10} style={{ textAlign: "center", paddingRight: "8px" }}>
                        <Select
                          labelId="C9"
                          name="C9"
                          value={C9}
                          onChange={handleChange}
                          style={{ textAlign: "center", backgroundColor: "#f3efef", width: "100%" }}
                        >
                          {bbattery.map(item => {
                            return (<MenuItem key={item.ID} value={item.battery}>{item.battery}</MenuItem>)
                          })}
                          {/* <MenuItem value={"None"}>None</MenuItem>
                        <MenuItem value={"Enphase 3kw"}>Enphase 3kw</MenuItem>
                        <MenuItem value={"Enphase 10kw"}>Enphase 10kw</MenuItem>
                        <MenuItem value={"Panasonic 11kw"}>Panasonic 11kw</MenuItem>
                        <MenuItem value={"Panasonic 17kw"}>Panasonic 17kw</MenuItem> */}
                        </Select>
                      </Grid>
                      <Grid item spacing={0} lg={4} md={4} sm={4} xs={2} style={{ textAlign: "center", paddingLeft: "8px" }}>
                        <Select
                          labelId="batteryCount"
                          name="batteryCount"
                          value={batteryCount}
                          onChange={handleChange}
                          style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%", width: "100%" }}
                        >
                          {batteryCountOptions.map(item => {
                            return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                          })}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center", color: "darkgrey" }}>
                    Select Energy Package
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -15 }}>
                    <Select
                      labelId="package"
                      name="package"
                      value={CPackage}
                      onChange={handleChange}
                      style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%" }}
                      defaultValue="N/A"
                      disabled
                    >
                      {/* { bbattery.map( item => {
                      return (<MenuItem key={item.ID} value={item.battery}>{item.battery}</MenuItem>)
                    })} */}
                      <MenuItem disabled value={"N/A"}>N/A</MenuItem>
                      <MenuItem disabled value={"None"}>None</MenuItem>
                      <MenuItem disabled value={"Basic w/o Foam"}>Basic w/o Foam</MenuItem>
                      <MenuItem disabled value={"Basic w/ Pool"}>Basic w/ Pool</MenuItem>
                      <MenuItem disabled value={"Premium"}>Premium</MenuItem>
                    </Select>
                  </Grid>


                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Enter System Size in Watts
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      name="C10"
                      value={C10}
                      id="C10"
                      InputProps={{
                        inputComponent: CountNumber,
                      }}
                      style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%" }}
                    />
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Total System Price from Sighten Proposal
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      name="C11"
                      value={C11}
                      id="C11"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%" }}
                    />
                  </Grid>

                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Select Dealer Fee
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -15 }}>
                    <Select
                      labelId="C14"
                      name="C14"
                      value={C14}
                      onChange={handleChange}
                      style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%" }}
                    >
                      {dealerFee.map(item => {
                        return (<MenuItem key={item.ID} value={item.Fee + "%"}>{item.Fee}%</MenuItem>)
                      })}
                    </Select>
                  </Grid>

                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    System Price without Dealer Fee
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -15 }}>
                    <TextField
                      name="woDealerFee"
                      id="woDealerFee"
                      value={woDealerFee}
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{textAlign: "center", width: "75%" }}
                      disabled
                    >
                    </TextField>
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center", fontWeight: "bolder" }}>
                    Add Cash Back <em>TO</em> Customer
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -15 }}>
                    <Select
                      labelId="C12"
                      name="C12"
                      value={C12}
                      onChange={handleChange}
                      style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%" }}
                    >
                      {ccash.map(item => {
                        return (<MenuItem key={item.ID} value={'$' + item.cash}>${item.cash}</MenuItem>)
                      })}
                      {/* <MenuItem value={"$0"}>$0</MenuItem>
                    <MenuItem value={"$1000"}>$1000</MenuItem>
                    <MenuItem value={"$2000"}>$2000</MenuItem> */}
                    </Select>
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center", fontWeight: "bolder" }}>
                    Total System Price after Cash Back
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      // onChange={handleChange}
                      name="C13"
                      value={C13}
                      id="C13"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>


                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Add Cash Payment <em>FROM</em> Customer
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      name="cashFromCust"
                      value={cashFromCust}
                      id="cashFromCust"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%" }}
                    />
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    System Price w/o Dealer Fee, + Cash Pmt
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      name="C15"
                      value={C15}
                      id="C15"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Solar Insure Adder (automatic)
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      name="C16"
                      value={C16}
                      id="C16"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Battery Price (automatic)
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      name="C17"
                      value={C17}
                      id="C17"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Total of ALL Adders (automatic) <br /> Go to left-side "Adders" tab
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      name="C18"
                      value={C18}
                      id="C18"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      disabled
                      style={{ backgroundColor: "rgb(227 227 255 / 25%)", width: "75%" }}
                    />
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center", fontWeight: "bolder" }}>
                    System Price (solar portion only)
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      name="C19"
                      value={C19}
                      id="C19"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled

                    />
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={12} style={{ textAlign: "center", fontWeight: "bolder" }}>
                    System Price per Watt (solar portion only)
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      name="C20"
                      value={C20}
                      id="C20"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      disabled
                      style={{ backgroundColor: "rgb(193 206 204)", width: "75%" }}
                    />
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>

                  </Grid>
                </Grid>
              </Paper>
            </Widget>
          </Grid>
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <Widget title="Commission Breakdown">
              <Paper style={{ paddingLeft: "4%", paddingRight: "4%" }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: "center", fontSize: 'larger' }}>
                    Sales Rep Name: {C5} <br />

                    Sales Rep Status: {C6} <br />

                    Manager: {manager}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    Total Overage/w
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: 8 }}>
                    <TextField
                      // onChange={handleChange}
                      name="F14"
                      value={F14}
                      id="F14"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Rep's Share of Overage/w
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onChange={handleChange}
                      name="F15"
                      value={F15}
                      id="F15"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    BaseLine Commission
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onChange={handleChange}
                      name="F17"
                      value={F17}
                      id="F17"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Self Gen Commission
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onChange={handleChange}
                      name="FSelfGen"
                      value={FSelfGen}
                      id="FSelfGen"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Overrage Commission
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onChange={handleChange}
                      name="F18"
                      value={F18}
                      id="F18"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Battery Commission
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>

                    <TextField
                      // onChange={handleChange}
                      name="F19"
                      value={F19}
                      id="F19"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Hours Since Last Commission
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      name="W2Hours"
                      value={dW2Hours}
                      id="W2Hours"
                      InputProps={{
                        inputComponent: CountNumber,
                      }}
                      style={{ textAlign: "center", backgroundColor: "#f3efef", width: "75%" }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Hours Worked
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onChange={handleChange}
                      name="W2Cost"
                      value={W2Cost}
                      id="W2Cost"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      style={{ width: "75%" }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
                    Total Commission (Solar+Storage)
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "center", marginTop: -12 }}>
                    <TextField
                      // onChange={handleChange}
                      name="F20"
                      value={F20}
                      id="F20"
                      InputProps={{
                        inputComponent: MoneyNumber,
                      }}
                      disabled
                      style={{ backgroundColor: "rgb(37 109 16)", width: "75%", color: "white", borderRadius: "5px" }}
                    />
                  </Grid>
                  <Grid item style={{ marginTop: 16, alignItems: 'center', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto' }}>
                    <iframe src="https://giphy.com/embed/uokhSmdmyfh2o" width="400" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/rocky-uokhSmdmyfh2o">via GIPHY</a></p>
                  </Grid>
                </Grid>
              </Paper>
            </Widget>
          </Grid>
        </Grid>
      }
    </>
  )

  // return (
  //   <>

  //   </>
  // );
}

function MoneyNumber(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

MoneyNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
function CountNumber(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

CountNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

import Calculator from "./calculator";

const getNum = (str) => {
    if(typeof str === "string"){
        var num;
        if(str.indexOf(",") > -1){
          var str_arr = str.split(",");
          var tempStr = "";
          str_arr.map((item) => {
            tempStr += item;
          })
          num = parseFloat(tempStr)
        }else{
          num = parseFloat(str)
        }
        return num;
    }else
    {
        return str;
    }
    
  }

const cutPercent = (str) => {
    console.log('incoming string: ', str);
    return 1 - parseFloat(str.split("%"))/100;
}

const cutDecimal = (data,limit,to) => {
    let numstr;
    let splitArray;
    let result;
    if(isNaN(data)){
        return null
    }else
    {
        if(typeof data === "string"){
            numstr = data;
        }else if(typeof data === "number"){
            numstr = data.toString();
        }else{
            return data;
        }
        if(numstr.indexOf(".") > -1){
            splitArray = numstr.split('.');
            if(splitArray[1].length > limit){
                result = Math.round(parseFloat(numstr)*Math.pow(10,limit))/Math.pow(10,limit);
                return to === "Number" ? result: result.toString();
            }else{
                return to === "Number" ? parseFloat(numstr): numstr 
            }
        }else{
            return to === "Number" ? parseFloat(numstr):numstr;
        }
    }
}

const ppwThresholdMission = 2.60;
const ppwThresholdREC = 2.95;
const W2HourlyRate = 20.0;

export const core = (formulasData,
                    calculatorData,
                    cashData = [],
                    feeData = [],
                    moduleData = [],
                    batteryData = [],
                    selfgenData = []) => {

                        var totalSystemAmount = getNum(calculatorData.C11);
                        var cashBack = calculatorData.C12;
                        var afterCashBack = getNum(calculatorData.C13);
                        var dealerFee = calculatorData.C14;
                        var cashFromCust = getNum(calculatorData.cashFromCust);
                        var repStatus = calculatorData.C6;
                        var selfGen = calculatorData.C7;
                        var module = calculatorData.C8;
                        var battery = calculatorData.C9;
                        let batteryCount = calculatorData.batteryCount;
                        const tempWattage = getNum(calculatorData.C10);
                        console.log(`tempWattage check ${tempWattage}`);
                        var totalWattage = tempWattage === "" ? 1 : tempWattage;
                        var woDealerFee = getNum(calculatorData.woDealerFee);
                        var totalWithoutDealerPlusCashFrom = parseFloat(calculatorData.C15);
                        var solarInsure = calculatorData.C16;
                        // TODO: why are we pulling this then overwriting?
                        var batteryPrice = getNum(calculatorData.C17);
                        var adders = getNum(calculatorData.C18);
                        var finalSystemPrice = getNum(calculatorData.C19);
                        var pricePerWatt = getNum(calculatorData.C20);
                        var status = calculatorData.C21;
                        var totalOverage = parseFloat(calculatorData.F14);
                        var repsShare = parseFloat(calculatorData.F15);
                        var baselineCommission = parseFloat(calculatorData.F17);
                        var selfGenCommission = getNum(calculatorData.FSelfGen);
                        var overageCommission = getNum(calculatorData.F18);
                        var batteryCommission = getNum(calculatorData.F19);
                        var totalCommission = calculatorData.F20;
                        // Don't allow negative numbers
                        let W2Hours = Math.abs(getNum(calculatorData.W2Hours));
                        
                        //Calculating Price Without Dealer Fee
                        var percent = dealerFee.split('%')[0]/100;
                        // console.log('percent', percent);
                        woDealerFee = totalSystemAmount - (totalSystemAmount * percent);
                        // console.log('percent', dealerFee.split('%')[0]/100)
                        
                        //Calculating Price After Cash Back
                        afterCashBack = woDealerFee - cashBack;

                        //Calculating Total without Dealer Fee, Plus Cash From Customer
                        totalWithoutDealerPlusCashFrom = woDealerFee - cashBack + cashFromCust;

                        //Calculating Solar Insure Adder
                        if (module === 'Mission 345-no frills') {
                            console.log('setting solarinsure to 0')
                            solarInsure = 0;
                        }else{
                            console.log('solar insure is not being set to zero')
                            solarInsure = totalWattage * 0.11;
                        };

                        //Calculating Battery Price
                        // TODO: move these into a data location
                        switch (battery) {
                          case 'Enphase 3kw':
                            batteryPrice = 9880;
                            break;
                          case 'Enphase 10kw':
                            batteryPrice = 16820;
                            break;
                          case 'Panasonic 11kw':
                            batteryPrice = 17940;
                            break;
                          case 'Panasonic 17kw':
                            batteryPrice = 20970;
                            break;
                          default:
                            batteryPrice = 0;
                            batteryCommission = 0;
                            break;
                        }
                        let totalBatteryPrice = batteryPrice * batteryCount;

                        //Calculating System Price Solar Portion Only
                        // finalSystemPrice = 0;
                        finalSystemPrice = totalWithoutDealerPlusCashFrom - solarInsure - totalBatteryPrice - adders;

                        //Calculating Price per Watt
                        pricePerWatt = getNum((finalSystemPrice / totalWattage).toFixed(2));
                        //console.log(`ppw dingus: ${pricePerWatt}`);

                        //Calculating Status;
                        // TODO: in this and future switches, the Module names
                        // are used as keys - this should be closely linked to
                        // a config or we should use an actual key. It's too
                        // easy to mess up on module name (esp. if they change)
                        switch(module) {
                          case 'Mission 345':
                            status = pricePerWatt < ppwThresholdMission ? 'REFUSED' : 'ACCEPTED';
                            break;
                          case 'Mission 345-no frills':
                            status = pricePerWatt < 2.6 ? 'REFUSED' : 'NO FRILLS';
                            break;
                          case 'REC 370':
                          case 'REC 400':
                            status = pricePerWatt < ppwThresholdREC ? 'REFUSED' : 'ACCEPTED';
                            break;
                          default:
                            // BAD STATE
                            status = 'REFUSED';
                        }

                        //Calculating Baseline Commission
                        switch (module) {
                          case 'Mission 345':
                            if (status === 'NO FRILLS') {
                                baselineCommission = 500;
                            }else{
                                baselineCommission = totalWattage * 0.12;
                            }
                            break;
                          case 'Mission 345-no frills':
                            if (status === 'NO FRILLS') {
                                baselineCommission = 500;
                            }else{
                                baselineCommission = 0;
                            }
                            break;
                          case 'REC 370':
                          case 'REC 400':
                          default:
                            baselineCommission = totalWattage * 0.14;
                            break;
                        }

                        //Calculating Total Overage
                        if (status !== 'REFUSED') {
                          let threshCheck = Infinity;
                          switch (module) {
                            case 'Mission 345':
                              threshCheck = ppwThresholdMission;
                              break;

                            case 'REC 370':
                            case 'REC 400':
                              threshCheck = ppwThresholdREC;
                              break;
                            default:
                              // BAD STATE
                              threshCheck = Infinity;
                          }
                          console.log('ppw Threshold', threshCheck);
                          // This conditional quells floating point goblins
                          // TODO: remove floating point math with money.
                          if (pricePerWatt >= threshCheck){
                            totalOverage = pricePerWatt - threshCheck;
                          } else {
                            totalOverage = 0;
                          }
                        } else {
                          totalOverage = 0;
                        }
                        console.log('total overage', totalOverage);
                      /*
                        if (module === 'Mission 345' && status !== 'REFUSED') {
                            if (pricePerWatt >= 2.9) {
                                totalOverage = pricePerWatt - 2.9;
                            }else{
                                totalOverage = 0;
                            }
                        }else if (module === 'REC 370' && status !== 'REFUSED') {
                            if (pricePerWatt >= 3.3) {
                                totalOverage = pricePerWatt - 3.3;
                            }else if (pricePerWatt >= 2.9) {
                                totalOverage = pricePerWatt - 2.9;
                            }
                        }else if (module === 'REC 400' && status !== 'REFUSED') {
                            if (pricePerWatt >= 3.35) {
                              totalOverage = pricePerWatt - 3.35;
                            }
                        }else{
                            totalOverage = 0;
                        };
                        */

                        //Calculating Rep's Share of Overage
                        if (repStatus === 'Bronze') {
                            repsShare = totalOverage * 0.3;
                        }else if (repStatus === 'Silver') {
                            repsShare = totalOverage * 0.4;
                        }else{
                            repsShare = totalOverage * 0.5;
                        };

                        //Calculating Overage Commission
                        overageCommission = repsShare * totalWattage;

                        //Calculating SelfGen Lead Commission
                        if (selfGen === 'Self') {
                            selfGenCommission = totalWattage * 0.04;
                        }else{
                            selfGenCommission = 0;
                        }

                        //Calculating Battery Commission
                        if (battery === 'None') {
                            batteryCommission = 0;
                        }else{
                            batteryCommission = 500 * batteryCount;
                        }

                        // Calculate W2 wage cost
                        let W2Cost = (-1.0 * W2Hours * W2HourlyRate) || 0;

                        //Calculating Total Commission
                        console.log(`total Commission calc: ${baselineCommission}, ${selfGenCommission}, ${overageCommission}, ${batteryCommission}, ${W2Cost}}`);
                        totalCommission = baselineCommission + selfGenCommission + overageCommission + batteryCommission + W2Cost;

                        console.log(`total Commission: ${totalCommission}`);

                        // TODO: We're modifying an inbound object to do this,
                        // but that's a code smell
                        calculatorData.C11 = totalSystemAmount;
                        calculatorData.C12 = cashBack;
                        calculatorData.C13 = afterCashBack.toFixed(2);
                        calculatorData.C14 = dealerFee;
                        calculatorData.cashFromCust = cashFromCust;
                        calculatorData.C6 = repStatus;
                        calculatorData.C7 = selfGen;
                        calculatorData.C8 = module;
                        calculatorData.C10 = totalWattage;
                        calculatorData.C15 = totalWithoutDealerPlusCashFrom.toFixed(0);
                        calculatorData.C16 = solarInsure.toFixed(2);
                        calculatorData.C17 = batteryPrice;
                        calculatorData.C18 = adders.toFixed(2);
                        calculatorData.C19 = finalSystemPrice.toFixed(2);
                        calculatorData.C20 = pricePerWatt.toFixed(2);
                        calculatorData.C21 = status;
                        calculatorData.F14 = totalOverage.toFixed(2);
                        calculatorData.F15 = repsShare.toFixed(2);
                        calculatorData.F17 = baselineCommission.toFixed(0);
                        calculatorData.FSelfGen = selfGenCommission.toFixed(0);
                        calculatorData.F18 = overageCommission.toFixed(0);
                        calculatorData.F19 = batteryCommission;
                        calculatorData.F20 = totalCommission.toFixed(0);
                        calculatorData.woDealerFee = woDealerFee.toFixed(2);
                        calculatorData.batteryCount = batteryCount;
                        calculatorData.W2Cost = W2Cost;

                        let data = {};
                        data.formulas = formulasData;
                        data.calculator = calculatorData;
                        console.log('returning core data: ', data.calculator);
                        return data;
                    }

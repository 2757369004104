import React from "react";

export const SummationCell = props => {
  const { dataItem } = props;
  return (
    <td className="k-command-cell" style={{textAlign: "center"}}>
      {dataItem.quantity}
    </td>
  );
}
